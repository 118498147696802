body, html {
	font-family: "Heebo", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Heebo", sans-serif;
}

p, a, li, span, div {
	font-family: "Lato", sans-serif;
}

.floating-ivw-logo {
	display: none;
}

.minimized .nav-desktop__lower {
	background: #CCCCCC;
}

.nav-mobile__upper {
	background: #CCCCCC !important;
}


.nav-social-media-links {
	padding-left: rem(14px);

	@include media-breakpoint-up(xl) {
		display: flex !important;
	}

	a {
		margin-left: 1.125rem;
		position: relative;
		overflow: hidden;
		display: block;
		height: 48px;
		width: 48px;

		.mdi {
			justify-content: center;
			align-items: center;
			display: flex;
			color: white;
			line-height: 2.5rem;
			font-size: 2.5rem;
			height: 48px;
			width: 48px;
		}
	}
}